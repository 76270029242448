import React, { useEffect } from 'react'
import Image from 'next/image';
import { useControls } from 'react-zoom-pan-pinch';
import styles from './ZoomInOut.module.css'
import { useGlobalContext } from '@/libs/context/GlobalContext';
import { useCanvasContext } from '@/libs/context/CanvasContext';

interface ZoomInOutProps {
    currentSlide: any;
    zoomIn: () => void;
    zoomOut: () => void;
  }
  export default function ZoomInOut({ currentSlide, zoomIn, zoomOut }: ZoomInOutProps) {
    const { props: { setStartZooming, startZooming, enableEditing, role  } } = useGlobalContext();
    const {setIsMagnifying,isCustomRequestSlide} =useCanvasContext()
    return (
      <div className={styles.zoomInOut}>
        <Image
          src={`/assets/images/${startZooming ? 'zoomIn' : 'zoom-inactive'}.svg`}
          alt='ZoomIn'
          width={20}
          height={30}
          onClick={() => {
            startZooming && zoomIn();
          }}
        />
        <Image
          className={`${styles.lock} ${(enableEditing || role === 'DQC') ? 'disabled' : ''}`}
          src={`/assets/images/${startZooming ? 'zoom-lock-inactive' : 'zoom-lock-active'}.svg`}
          alt='ZoomLock'
          width={25}
          height={30}
          onClick={() => {
            if (!(enableEditing || role === 'DQC' || isCustomRequestSlide)) {
              setStartZooming(!startZooming);
              setIsMagnifying!(false)
            }
          }}
        />
        <Image
          src={`/assets/images/${startZooming ? 'zoomOut' : 'zoom-out-inactive'}.svg`}
          alt='ZoomOut'
          width={20}
          height={30}
          onClick={() => {
            startZooming && zoomOut();
          }}
        />
        {/* <button onClick={resetZoom}>Reset</button> */}
      </div>
    )}