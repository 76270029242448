import MaterialOrder from '@/data/MaterialOrder.json'
import { ITools } from '@/interfaces';


export const getMaterialData = (materialData: any) => {
  const slideData: any = [];
  let slide: any = [];
  let slideCatCount = 0;
  for (const m of materialData.data) {
    let catArr = [];
    let _count = 0;
    for (const c of m.data) {
      if (_count >= 8 || (slideCatCount == 1 && _count >= 4)) {
        slide.push({
          category: m.category,
          data: catArr,
        });
        pushSlide();
        catArr = [];
        slide = [];
        _count = slideCatCount = 0;
      }
      catArr.push(c);
      _count++;
    }
    slide.push({
      category: m.category,
      data: catArr,
    });
    slideCatCount++;
    if (slideCatCount >= 2 || (slideCatCount == 1 && _count > 4)) {
      pushSlide();
      slide = [];
      slideCatCount = 0;
    }
  }
  if (slide && slide.length > 0) {
    pushSlide();
  }

  function pushSlide() {
    slideData.push({
      type:
        slideData.length == 0 ? materialData.type : `${materialData.type} Cont`,
      data: slide,
      slideId: `${materialData.type.toLocaleLowerCase().replaceAll(" ", "_")}_${
        slideData.length + 1
      }`,
    });
  }
  return slideData;
};
export const getMaterialDataV2 = (materialData: any,roomtype:string) => {
  const a:any = MaterialOrder
  const roomOrder:any = a[roomtype.toLowerCase().replace(/\s+/g, '_')] || [];
  
  const sortCategory = (category:any) => {
    const orderItem = roomOrder.find((item:any) => item.type.toLowerCase().replace(/\s+/g, '_') === category.toLowerCase().replace(/\s+/g, '_'));
    return orderItem ? orderItem.order : Infinity;
  };

  materialData.data.sort((a:any, b:any) => sortCategory(a.category) - sortCategory(b.category));
  const slideData: any = [];
  let slide: any = [];
  let slideCatCount = 0;
  for (const m of materialData.data) {
    let catArr = [];
    let _count = 0;
    for (const c of m.data) {
      if (_count >= 8 || (slideCatCount == 1 && _count >= 4)) {
        slide.push({
          category: m.category,
          data: catArr,
        });
        pushSlide();
        catArr = [];
        slide = [];
        _count = slideCatCount = 0;
      }
      catArr.push(c);
      _count++;
    }
    slide.push({
      category: m.category,
      data: catArr,
    });
    slideCatCount++;
    if (slideCatCount >= 2 || (slideCatCount == 1 && _count > 4)) {
      pushSlide();
      slide = [];
      slideCatCount = 0;
    }
  }
  if (slide && slide.length > 0) {
    pushSlide();
  }

  function pushSlide() {
    slideData.push({
      type:
        slideData.length == 0 ? materialData.type : `${materialData.type} Cont`,
      data: slide,
      slideId: `${materialData.type.toLocaleLowerCase().replaceAll(" ", "_")}_${
        slideData.length + 1
      }`,
    });
  }
  return slideData;
};

export const findMaxAttributesLength = (data: any) => {
  // Function to sum the lengths of all attributes arrays in a given array
  const sumAttributesLength = (items: any) => {
    return items.reduce(
      (sum: any, item: any) => sum + item.attributes.length,
      0
    );
  };

  // Initialize maximum length variables
  let maxAccessoriesLength = 0;
  let maxMaterialSpecsLength = 0;
  let maxExposedSidesLength = 0;
  let maxInstructionsLength = 0;

  // Calculate the sum of attributes lengths for each array
  if (data.accessories) {
    maxAccessoriesLength = sumAttributesLength(data.accessories);
  }
  if (data.materialSpecs) {
    maxMaterialSpecsLength = sumAttributesLength(data.materialSpecs);
  }
  if (data.exposedSides) {
    maxExposedSidesLength = sumAttributesLength(data.exposedSides);
  }
  if (data.instructions) {
    maxInstructionsLength = sumAttributesLength(data.instructions);
  }

  // Determine the highest sum overall
  const highestLength = Math.max(
    maxAccessoriesLength,
    maxMaterialSpecsLength,
    maxExposedSidesLength,
    maxInstructionsLength
  );

  return highestLength;
};

export const formatdataV1 = (type: string, data: any) => {
  const paginatedData: any[] = [];
  const rowsPerPage = 5;
  const dataPerPage = 20;
  let page = 1;
  let currentPageData: any[] = [];
  let currentMaxAttrSum = 0;

  if (data && Array.isArray(data) && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const maxAttr = findMaxAttributesLength(data[i]);

      if (currentPageData.length === 0) {
        // First row in the slide
        if (maxAttr > dataPerPage) {
          // If maxAttr is greater than dataPerPage, start a new slide with this row
          if (currentPageData.length > 0) {
            paginatedData.push({
              type: page === 1 ? type : `${type} Cont`,
              data: [...currentPageData],
              slideId: `${type
                .toLocaleLowerCase()
                .replaceAll(" ", "_")}_${page}`,
            });
            page++;
            currentPageData = [];
          }
        }
        currentPageData.push(data[i]);
        currentMaxAttrSum = maxAttr;
      } else {
        // Not the first row in the slide
        if (
          currentMaxAttrSum + maxAttr > dataPerPage ||
          currentPageData.length === rowsPerPage
        ) {
          // If the sum of maxAttrSum and half of maxAttr exceeds dataPerPage or we've reached the row limit, start a new slide
          paginatedData.push({
            type: page === 1 ? type : `${type} Cont`,
            data: [...currentPageData],
            slideId: `${type.toLocaleLowerCase().replaceAll(" ", "_")}_${page}`,
          });
          page++;
          currentPageData = [];
          currentMaxAttrSum = 0;
        }
        currentPageData.push(data[i]);
        currentMaxAttrSum += maxAttr;
      }
    }

    // Add any remaining data that didn't fill up a full page
    if (currentPageData.length > 0) {
      paginatedData.push({
        type: page === 1 ? type : `${type} Cont`,
        data: [...currentPageData],
        slideId: `${type.toLocaleLowerCase().replaceAll(" ", "_")}_${page}`,
      });
    }
  }

  return paginatedData;
};

export const formatdata = (type: string, maxVal: number, data: any) => {
  const maxItemsPerPage = maxVal;
  const totalPages = (data && Math.ceil(data.length / maxItemsPerPage)) || 1;
  const paginatedData = [];

  if (data && Array.isArray(data) && data.length > 0) {
    for (let page = 1; page <= totalPages; page++) {
      const start = (page - 1) * maxItemsPerPage;
      const end = start + maxItemsPerPage;
      const newData = {
        type: page === 1 ? type : `${type} Cont`,
        data: maxVal === 1 ? data[start] : data.slice(start, end),
        slideId:
          page === 1
            ? `${type.toLocaleLowerCase().replaceAll(" ", "_")}`
            : `${type.toLocaleLowerCase().replaceAll(" ", "_")}_${page}`,
      };

      if (newData?.data?.table && newData?.data?.table.length != 0) {
        const perPageTableData = 10;
        const tablePages = Math.ceil(
          newData.data.table.length / perPageTableData
        );
        for (let tablePage = 1; tablePage <= tablePages; tablePage++) {
          const tableStart = (tablePage - 1) * perPageTableData;
          const tableEnd = tableStart + 14;
          const tableData = newData.data.table.slice(tableStart, tableEnd);
          paginatedData.push({
            type: tablePage == 1 ? `${type} Table` : `${type} Table Cont`,
            data: {
              image: null,
              name: newData.data.name,
              table: tableData,
            },
            slideId:
              tablePage == 1
                ? `${newData.slideId}_${type
                    .toLocaleLowerCase()
                    .replaceAll(" ", "_")}_table`
                : `${newData.slideId}_${type
                    .toLocaleLowerCase()
                    .replaceAll(" ", "_")}_table_${tablePage}`,
          });
        }
      } else {
        paginatedData.push(newData);
      }
    }
  }

  if (paginatedData && paginatedData.length == 1 && !paginatedData[0].data)
    paginatedData[0].data = [];

  return paginatedData;
};
export const formatdataV2 = (type: string, maxVal: number, data: any) => {
  const maxItemsPerPage = maxVal;
  const totalPages = (data && Math.ceil(data.length / maxItemsPerPage)) || 1;
  const paginatedData = [];

  if (data && Array.isArray(data) && data.length > 0) {
    for (let page = 1; page <= totalPages; page++) {
      const start = (page - 1) * maxItemsPerPage;
      const end = start + maxItemsPerPage;
      const newData = {
        type: page === 1 ? type : `${type} Cont`,
        data: maxVal === 1 ? data[start] : data.slice(start, end),
        slideId: `${type.toLocaleLowerCase().replaceAll(" ", "_")}_${data[
          start
        ].name
          .toLocaleLowerCase()
          .replaceAll(" ", "_")}`,
      };

      if (newData?.data?.table && newData?.data?.table.length != 0) {
        const perPageTableData = 10;
        const tablePages = Math.ceil(
          newData.data.table.length / perPageTableData
        );
        for (let tablePage = 1; tablePage <= tablePages; tablePage++) {
          const tableStart = (tablePage - 1) * perPageTableData;
          const tableEnd = tableStart + 14;
          const tableData = newData.data.table.slice(tableStart, tableEnd);
          paginatedData.push({
            type: tablePage == 1 ? `${type} Table` : `${type} Table Cont`,
            data: {
              image: null,
              name: newData.data.name,
              table: tableData,
            },
            slideId:
              tablePage == 1
                ? `${newData.slideId}_${type
                    .toLocaleLowerCase()
                    .replaceAll(" ", "_")}_table`
                : `${newData.slideId}_${type
                    .toLocaleLowerCase()
                    .replaceAll(" ", "_")}_table_${tablePage}`,
          });
        }
      } else {
        paginatedData.push(newData);
      }
    }
  }

  if (paginatedData && paginatedData.length == 1 && !paginatedData[0].data)
    paginatedData[0].data = [];

  return paginatedData;
};
export const formatdataOrderV2 = (type: string, maxVal: number, data: any,roomtype:string) => {
  const a:any = MaterialOrder
  const roomOrder:any = a[roomtype.toLowerCase().replace(/\s+/g, '_')] || [];
  const sortCategory = (name: string) => {
    const orderItem = roomOrder.find((item:any) => item.type.toLowerCase().replace(/\s+/g, '_') === name.toLowerCase().replace(/\s+/g, '_'));
    return orderItem ? orderItem.order : Infinity;
  };

  if (data && Array.isArray(data)) {
    data.sort((a: any, b: any) => sortCategory(a.name) - sortCategory(b.name));
  }


  const maxItemsPerPage = maxVal;
  const totalPages = (data && Math.ceil(data.length / maxItemsPerPage)) || 1;
  const paginatedData = [];

  if (data && Array.isArray(data) && data.length > 0) {
    for (let page = 1; page <= totalPages; page++) {
      const start = (page - 1) * maxItemsPerPage;
      const end = start + maxItemsPerPage;
      const newData = {
        type: page === 1 ? type : `${type} Cont`,
        data: maxVal === 1 ? data[start] : data.slice(start, end),
        slideId: `${type.toLocaleLowerCase().replaceAll(" ", "_")}_${data[
          start
        ].name
          .toLocaleLowerCase()
          .replaceAll(" ", "_")}`,
      };

      if (newData?.data?.table && newData?.data?.table.length != 0) {
        const perPageTableData = 10;
        const tablePages = Math.ceil(
          newData.data.table.length / perPageTableData
        );
        for (let tablePage = 1; tablePage <= tablePages; tablePage++) {
          const tableStart = (tablePage - 1) * perPageTableData;
          const tableEnd = tableStart + 14;
          const tableData = newData.data.table.slice(tableStart, tableEnd);
          paginatedData.push({
            type: tablePage == 1 ? `${type} Table` : `${type} Table Cont`,
            data: {
              image: null,
              name: newData.data.name,
              table: tableData,
            },
            slideId:
              tablePage == 1
                ? `${newData.slideId}_${type
                    .toLocaleLowerCase()
                    .replaceAll(" ", "_")}_table`
                : `${newData.slideId}_${type
                    .toLocaleLowerCase()
                    .replaceAll(" ", "_")}_table_${tablePage}`,
          });
        }
      } else {
        paginatedData.push(newData);
      }
    }
  }

  if (paginatedData && paginatedData.length == 1 && !paginatedData[0].data)
    paginatedData[0].data = [];




  return paginatedData;
};

export const findData = (
  orderedSlideDataApi: any,
  projectDetails: any,
  materialData: any,
  roomtype:string
) => {
  return orderedSlideDataApi.map((f: any) => {
    switch (f.type) {
      case "Wall Elevations":
        return formatdata(f.type, 4, f.data);
      case "Elevations":
        if ("version" in projectDetails) {
          if (projectDetails.version === 2) {
            return formatdataV2(f.type,1, f.data);
          }
        }
        return formatdata(f.type, 1, f.data);
      case "Cabinet Views":
        if ("version" in projectDetails) {
          if (projectDetails.version === 2) {
            return formatdataOrderV2(f.type,1, f.data,roomtype);
          }
        }
        return formatdata(f.type, 1, f.data);
      case "Top 3D View":
      case "Plan View":
      case "Floorplan":
        return formatdata(f.type, 1, f.data);
      case "Cabinet List":
        if ("version" in projectDetails) {
          if (projectDetails.version === 1 || projectDetails.version==2) {
            return formatdataV1(f.type, f.data);
          }
        }
        return formatdata(f.type, 3, f.data);
      case "Accessory List":
      case "Wall 3D Views":
        return formatdata(f.type, 4, f.data);
      case "Materials":
        if ("version" in projectDetails){
          if (projectDetails.version === 2) {
            return getMaterialDataV2(materialData,roomtype);
          }
        }
        return getMaterialData(materialData);
      default:
        return [f];
    }
  });
};
export const updateEditorjsonV2 = (editorJson: ITools[], slideDataApi: any[]) => {
  // Check if any slide already has version 2
  const hasVersion2 = editorJson.some((item: ITools) => item.otherData.version === 2);

  // If version 2 exists, return the original editorJson
  if (hasVersion2 || editorJson.length === 0) {
    return editorJson;
  }

  const elevations = slideDataApi.find((item: any) => item.type === 'Elevations')?.data || [];
  const cabinetViews = slideDataApi.find((item: any) => item.type === 'Cabinet Views')?.data || [];

  // Update the editorJson
  const updatedEditorJson = editorJson.map((item: ITools) => {
    let updatedItem = { 
      ...item, 
      otherData: { 
        ...item.otherData, 
        version: 2  // Update version to 2
      } 
    };

    if (item.slideId.includes('elevations')) {
      const match:any = item.slideId.match(/elevations(_\d+)?/);
      const index = match[1] ? parseInt(match[1].substring(1)) - 1 : 0;
      if (index < elevations.length) {
        const newSlideId = `elevations_${elevations[index].name.toLowerCase().replace(/\s+/g, '_')}`;
        updatedItem.slideId = newSlideId;
      }
    } else if (item.slideId.includes('cabinet_views')) {
      const match:any = item.slideId.match(/cabinet_views(_\d+)?/);
      const index = match[1] ? parseInt(match[1].substring(1)) - 1 : 0;
      if (index < cabinetViews.length) {
        const newSlideId = `cabinet_views_${cabinetViews[index].name.toLowerCase().replace(/\s+/g, '_')}`;
        updatedItem.slideId = newSlideId;
      }
    }

    return updatedItem;
  });

  return updatedEditorJson;
}

export const updateCommentsjsonV2 = (commentsJson: ITools[], slideDataApi: any[]) => {
  // Check if any slide already has version 2
  const hasVersion2 = commentsJson.some((item: ITools) => item.otherData.version === 2);

  // If version 2 exists, return the original commentsJson
  if (hasVersion2 || commentsJson.length === 0) {
    return commentsJson;
  }

  const elevations = slideDataApi.find((item: any) => item.type === 'Elevations')?.data || [];
  const cabinetViews = slideDataApi.find((item: any) => item.type === 'Cabinet Views')?.data || [];

  // Update the commentsJson
  const updatedCommentsJson = commentsJson.map((item: any) => {
    let updatedItem = { 
      ...item, 
      otherData: { 
        ...item.otherData, 
        version: 2  // Update version to 2
      } 
    };

    if (item.slideId.includes('elevations')) {
      const match = item.slideId.match(/elevations(_\d+)?/);
      const index = match[1] ? parseInt(match[1].substring(1)) - 1 : 0;
      if (index < elevations.length) {
        const newSlideId = `elevations_${elevations[index].name.toLowerCase().replace(/\s+/g, '_')}`;
        updatedItem.slideId = newSlideId;
      }
    } else if (item.slideId.includes('cabinet_views')) {
      const match = item.slideId.match(/cabinet_views(_\d+)?/);
      const index = match[1] ? parseInt(match[1].substring(1)) - 1 : 0;
      if (index < cabinetViews.length) {
        const newSlideId = `cabinet_views_${cabinetViews[index].name.toLowerCase().replace(/\s+/g, '_')}`;
        updatedItem.slideId = newSlideId;
      }
    }

    return updatedItem;
  });

  return updatedCommentsJson;
}

export const createIdSlideIdSets = (jsonData: any[]) => {
  const idSlideIdMap: { [key: string]: number } = {};

  jsonData.forEach(item => {
    // Ensure we're always using the most recent id for a given slideId
    idSlideIdMap[item.slideId] = item.id;
  });

  return idSlideIdMap;
}

export const formatCustomSlide = (customSlide:any[]) => {
  return customSlide
  .map(item => ({
    ...item,
    type: "Custom",
    data: item.tools,
  }));
};

export const updateEditorJson=(editorJson: any[], responseData: any)=> {
  // Iterate through each item in the response data
  responseData.forEach((responseItem: any) => {
    const existingSlideIndex = editorJson.findIndex(item => item.slideId === responseItem.slideId);

    if (existingSlideIndex !== -1) {
      // If the slideId already exists, update the shapes
      responseItem.tools.forEach((responseTool: any) => {
        const existingToolIndex = editorJson[existingSlideIndex].tools.findIndex(
          (tool: any) => tool.data.id === responseTool.data.id
        );

        if (existingToolIndex !== -1) {
          // If the tool already exists, replace it
          editorJson[existingSlideIndex].tools[existingToolIndex] = responseTool;
        } else {
          // If the tool doesn't exist, add it
          editorJson[existingSlideIndex].tools.push(responseTool);
        }
      });
    } else {
      // If the slideId doesn't exist, add the entire new item
      editorJson.push(responseItem);
    }
  });

  return editorJson;
}

export const getThumbnailByType = (slide: any) => {
  switch (slide.type.replace(/\s+/g, '').toLowerCase()) {
    case 'projectdetail':
      return '/assets/thumbnails/ProjectDetails.png';
    case 'floorplan':
    case 'top3dview':
    case 'room3dviews':
    case 'room3dviewscont':
    case 'planview':
    case 'cabinetviews':
    case 'cabinetviewscont':
    case 'elevations':
    case 'elevationscont':
      return slide?.data?.image;
    case 'wall3dview':
    case 'wall3dviewcont':
    case 'wall3dviews':
    case 'wall3dviewscont':
    case 'structuralenitityviews':
    case 'structuralenitityviewscont':
      return slide?.data[0]?.image;
    case 'materials':
    case 'materialscont':
      return '/assets/thumbnails/Materials.svg';
    case 'addonstable':
    case 'addonstablecont':
      return '/assets/thumbnails/AddOns.svg';
    case 'cabinetlist':
    case 'cabinetlistcont':
      return '/assets/thumbnails/CabinetList.svg';
    case 'accessorylist':
    case 'accessorylistcont':
      return '/assets/thumbnails/AccessoryList.svg';
    case 'custom':
      return '/assets/thumbnails/Custom.svg';
    case 'handlelist':
    case 'handlelistcont':
    case 'handlelist(exceptions)':
      return '/assets/thumbnails/HandleList.svg';
    default:
      return '/assets/thumbnails/Default.svg';
  }
};
