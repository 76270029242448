import styles from './GalleryPopup.module.css';
import Image from 'next/image';
import { GalleryViewPopup } from '../galleryViewPopup';
import { useEffect, useState } from 'react';
import { IGallery } from '@/interfaces/IHomeContent';

type props = {
    handleGalleryPopup: Function,
    gallery:any
}
export function GalleryPopup({ handleGalleryPopup,gallery }: props) {
    // const roomImages: any = galleryImages?.find((f: any) => f.roomId == 3) || {};
    const [showGalleryPopup, setShowGalleryPopup] = useState(true);
    const [isShowImagePopup, setIsShowImagePopup] = useState(false);
    const [selectImageIdx, setSelectedImageIndex] = useState(-1);
    const [selectRoomIdx, setSelectedRoomIndex] = useState(-1);
    useEffect(() => {
        if (isShowImagePopup) {
            setShowGalleryPopup(false)
        } else {
            setShowGalleryPopup(true)
        }
    }, [isShowImagePopup])

    return <>
    {isShowImagePopup && (
        <GalleryViewPopup
            setIsShowImagePopup={setIsShowImagePopup}
            roomImages={gallery![selectRoomIdx]}
            selectImageIdx={selectImageIdx}
            handleGalleryPopup={handleGalleryPopup}
        />
    )}
    {showGalleryPopup && (
        <div className={styles.dEditmainpopbox}>
            <div className={styles.dediterInermbox}>
                <div className={styles.editerpopHead}>
                    <div className={styles.editerGallbox}>
                        <Image
                            height={18}
                            width={18}
                            src="/assets/images/galleryimg.svg"
                            alt=""
                        />
                        <span className="font12 textfff">Gallery</span>
                    </div>
                    <div
                        className={styles.ediCrossbtbox}
                        onClick={() => handleGalleryPopup(0)}
                    >
                        <Image
                            width={14}
                            height={14}
                            src="/assets/images/cross.svg"
                            alt=""
                        />
                    </div>
                </div>
                <div className={styles.materBedmiabox}>
                    {gallery && gallery.length > 0 && (
                        gallery.map((roomImages:any, index:number) => (
                            <div key={index} className={styles.imageWrapper}>
                                <div className={`${styles.masteDhead} mb12`}>
                                    <span className="font12 text000">{roomImages?.roomName}</span>
                                </div>
                                <div className={styles.masterbeDmaimgb}>
                                    {roomImages?.galleries?.map((image: IGallery, idx: number) => (
                                        <div
                                            className={styles.masterbadImgtiles}
                                            key={idx}
                                            onClick={() => (
                                                setIsShowImagePopup(true),
                                                setSelectedImageIndex(idx),
                                                setSelectedRoomIndex(index)
                                            )}
                                        >
                                            <Image
                                                src={`https://img.squareyards.com/${image.filePath}`}
                                                alt=""
                                                width={98}
                                                height={64}
                                            />
                                            <div className={styles.poupStrimgbox}>
                                                <Image
                                                    src="/assets/images/popimgstrach.svg"
                                                    height={18}
                                                    width={18}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    )}
</>
}